import styled from 'styled-components';

export const GoogleMapContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 800px;
  @media (max-width: 1199px) {
    height: 600px;
  }
  @media (max-width: 991px) {
    top: -55px;
  }
`;
